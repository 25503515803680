import { registerApplication, start } from "single-spa";
import Keycloak from "keycloak-js";
import "./css/shared.css";
import { checkPath, checkSentryErrors } from "./helper/utils";
import * as Sentry from "@sentry/browser";
// import { BrowserTracing } from "@sentry/tracing";
import pjson from "../package.json";
import {
  getUserDetails,
  getMerchantConfig,
  getSectionsConfig,
  loadTranslations,
} from "./services/common";
import { ignoredSentryErrors, MERCHANT_ADMIN_ROLE } from "./constants";
import { WebSocketConnection } from "./services/websocket";

import "@mdi/font/css/materialdesignicons.css";
const isLocal = process.env.isLocal;

const eventData = [];
let realEstateDefaultDSN =
  "https://2173c4daf9c043ab95032a2f612edd8b@sentry.ottu.net/11";

let sentryUrl = "https://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};

  realEstateDefaultDSN = sentryUrl =
    "https://cc29b1e0b30c441a83ce84f12e46ca16@o1152525.ingest.sentry.io/6250727";
}

sentryUrl = process.env.VUE_APP_SENTRY_URL || sentryUrl;

Sentry.setTag("microfrontend", "root_conf");
Sentry.init({
  dsn: sentryUrl,
  release: `root_conf@${pjson.version}`,
  // integrations: [new BrowserTracing()],
  ignoreErrors: ignoredSentryErrors,
  beforeSend: checkSentryErrors,
  // tracesSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
  sampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
  autoSessionTracking: false,
  enabled: !isLocal,
});

var token = "";
const sharedMFState = {
  isLoadingConfig: true,
  user: null,
  config: null,
  sections: null,
};

const socketConnection = new WebSocketConnection();

const isKeycloakAuth = process.env.ENABLE_KEYCLOACK === "true";
const changeState = async (value) => {
  sharedMFState.isLoadingConfig = true;
  if (value === "state") {
    const [user, config, sections] = await Promise.all([
      getUserDetail(),
      getConfig(),
      getSections(),
    ]);
    socketConnection.createSocketConnection(
      user.id,
      config.websocket_url,
      eventData
    );
    sharedMFState.config = config;
    sharedMFState.user = user;
    sharedMFState.sections = sections;
    sharedMFState.isLoadingConfig = false;
  }
};

const options = {
  pkceMethod: process.env.KEYCLOAK_PKCE_METHOD || "S256",
  url: `https://${process.env.VUE_APP_SSO_DOMAIN}/auth/`,
  realm: process.env.MERCHANT_ID,
  clientId: process.env.KEYCLOAK_CLIENT_ID || "frontend",
  onLoad: process.env.KEYCLOAK_ON_LOAD || "login-required",
};

const _keycloak = new Keycloak(options);
const noNavBar = checkPath(window.location.pathname);
const isLoginRoute =
  document.URL.includes("/login/") || document.URL.endsWith("/login");
const isTokenNotStored =
  !localStorage.getItem("VUE_APP_TOKEN") ||
  localStorage.getItem("VUE_APP_TOKEN") === "undefined";

_keycloak
  .init({ checkLoginIframe: false })
  .then(async () => {
    setLocalStorage(_keycloak.tokenParsed?.exp, _keycloak.idToken);

    const [config, arabicTranslation] = await Promise.all([
      getConfig(),
      getTranslations(),
    ]);

    setConfigToLocalStorage(config);

    if (!_keycloak.authenticated && !noNavBar && isKeycloakAuth) {
      if (!isLoginRoute) {
        if (
          isTokenNotStored &&
          config.website &&
          !document.URL.includes(config.website.toString())
        ) {
          window.location.href = config.website;
          return;
        }
      }
      _keycloak.login({
        redirectUri: window.location.href,
      });
    } else if (
      isTokenNotStored &&
      !noNavBar &&
      !isKeycloakAuth &&
      !isLoginRoute &&
      config.website &&
      !document.URL.includes(config.website.toString())
    ) {
      window.location.href = config.website;
      return;
    } else {
      const userLocaleFromKeycloak = _keycloak.tokenParsed
        ? _keycloak.tokenParsed.locale || "en"
        : undefined;
      setLocale(userLocaleFromKeycloak);

      let user, sections;
      token = _keycloak.idToken;
      if (token) {
        const [userRes, sectionsRes] = await Promise.all([
          getUserDetail(),
          getSections(),
        ]);
        user = userRes;
        sections = sectionsRes;
        socketConnection.createSocketConnection(
          user.id,
          config.websocket_url,
          eventData
        );
      }
      sharedMFState.config = config;
      sharedMFState.user = user;
      sharedMFState.sections = sections;
      sharedMFState.isLoadingConfig = false;
      /*global System*/
      /*eslint no-undef: "error"*/
      const isMerchantAdmin = checkIsMerchantAdmin(
        _keycloak?.tokenParsed?.realm_access?.roles || []
      );
      registerApplication({
        name: "@ottu-mf/navigation",
        app: () =>
          System.import("@ottu-mf/navigation").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) =>
            (!location.pathname.includes("/checkout") ||
              location.pathname.includes("/catalogue")) &&
            !location.pathname.includes("/pos/attempt-detail") &&
            !location.pathname.includes("/payment/") &&
            !location.pathname.includes("/property-maintenance/"),
        ],
        customProps: {
          idToken,
          refreshToken,
          logout,
          translations,
          sharedMFState,
          arabicTranslation,
          ignoredSentryErrors,
          checkSentryErrors,
          ws_notification: eventData,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          serverOrigin: process.env.VUE_APP_CORE_BACKEND_URL,
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          isKeycloakAuth,
          isLocal,
          merchant: process.env.MERCHANT_ID,
          keycloakDomain: process.env.VUE_APP_SSO_DOMAIN,
          isMerchantAdmin,
        },
      });

      registerApplication({
        name: "@ottu-mf/public_pages",
        app: () =>
          System.import("@ottu-mf/public_pages").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) =>
            location.pathname.includes("/checkout") &&
            !location.pathname.includes("/catalogue"),
          (location) => location.pathname.includes("/pos/attempt-detail"),
          (location) => location.pathname.includes("/payment/"),
          (location) => location.pathname.includes("/property-maintenance"),
        ],
        customProps: {
          arabicTranslation,
          translations,
          showFromforPaymentPage:
            process.env.VUE_APP_SHOW_FROM_IN_PAYMENT_DETAILS_PAGE === "false"
              ? false
              : true,
          sentryUrl,
          sharedMFState,
          socketConnection: socketConnection,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          ignoredSentryErrors,
          checkSentryErrors,
          isLocal,
          checkoutSDKLink:
            process.env.VUE_APP_CHECKOUT_SDK_URL ||
            "https://assets.ottu.net/checkout/v3/checkout.min.js",
          payButtonText: process.env.PAY_BUTTON_LABEL || null,
          displayRejectButton: process.env.DISPLAY_REJECT_BUTTON === "true",
        },
      });

      registerApplication({
        name: "@ottu-mf/frontend",
        app: () =>
          System.import("@ottu-mf/frontend").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) => location.pathname.includes("/transactions"),
          (location) => location.pathname.includes("/bulk/"),
          (location) => location.pathname.includes("/catalogue"),
          (location) => location.pathname.includes("/customer-payment"),
          (location) => location.pathname.includes("/e-commerce"),
          (location) => location.pathname.includes("/event"),
          (location) => location.pathname.includes("/dashboard"),
          (location) => location.pathname.includes("/payment-request"),
          (location) => location.pathname.includes("/generated-reports"),
          (location) => location.pathname.includes("/food_ordering"),
          (location) => location.pathname.includes("/session"),
          (location) => location.pathname.includes("/shopify"),
          (location) => location.pathname.includes("/invoices"),
          (location) => location.pathname.includes("/user"),
          (location) => location.pathname.includes("/help"),
          (location) => location.pathname.includes("/cpr"),
          (location) => location.pathname.includes("/events"),
          (location) => location.pathname.includes("/menu"),
          (location) => location.pathname.includes("/spf"),
          (location) => location.pathname.includes("/ticket"),
          (location) =>
            location.pathname.includes("/pos") &&
            !location.pathname.includes("/pos/attempt-detail"),
          (location) => location.pathname.includes("/real-estate"),
          (location) => location.pathname.includes("/search"),
          (location) => location.pathname.includes("/login"),
          (location) => location.pathname.includes("/forget-password"),
        ],
        customProps: {
          idToken,
          refreshToken,
          logout,
          translations,
          sharedMFState,
          ignoredSentryErrors,
          checkSentryErrors,
          arabicTranslation,
          noNavBar,
          ws_notification: eventData,
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          customStyleDirectoryName:
            process.env.VUE_APP_CUSTOM_STYLE_DIRECTORY_NAME,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          checkoutSDKLink:
            process.env.VUE_APP_CHECKOUT_SDK_URL ||
            "https://assets.ottu.net/checkout/v3/checkout.min.js",
          showFromforPaymentPage:
            process.env.VUE_APP_SHOW_FROM_IN_PAYMENT_DETAILS_PAGE === "false"
              ? false
              : true,
          isKeycloakAuth,
          changeState,
          isLocal,
          showUnitCodes:
            process.env.VUE_APP_SHOW_UNIT_CODES === "false" ? false : true,
        },
      });

      registerApplication({
        name: "@ottu-mf/estate",
        app: () =>
          System.import("@ottu-mf/estate").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [(location) => location.pathname.includes("/real_estate")],
        customProps: {
          idToken,
          logout,
          ws_notification: eventData,
          merchant: process.env.MERCHANT_ID,
          translations,
          ignoredSentryErrors,
          checkSentryErrors,
          sentryUrl: realEstateDefaultDSN,
          isLocal: isLocal,
        },
      });

      registerApplication({
        name: "@ottu-mf/fnb_frontend",
        app: () =>
          System.import("@ottu-mf/fnb_frontend").catch((err) =>
            console.log("Error while loading:", err)
          ),
        activeWhen: [
          (location) => location.pathname.includes("/new-menu"),
          (location) => location.pathname.includes("/food-ordering"),
        ],
        customProps: {
          idToken,
          logout,
          ignoredSentryErrors,
          checkSentryErrors,
          arabicTranslation,
          ws_notification: eventData,
          merchant: process.env.VUE_APP_CORE_SERVER_MERCHANT,
          translations,
          sentryUrl,
          sentrySampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
          // traceSampleRate: process.env.VUE_APP_TRACE_SAMPLE_RATE || 0.2,
          noNavBar: noNavBar || location.pathname.includes("/duplicate"),
          orderRefreshTime: process.env.VUE_APP_ORDER_LIST_REFRESH_TIME || 60,
          foodOrderingColor:
            process.env.VUE_APP_FOOD_ORDERING_MAIN_COLOR || "#3cb156",
          isLocal,
        },
      });
    }
  })
  .catch((err) => {
    console.log("Error: ", err);
  });

export function idToken() {
  if (isKeycloakAuth) return token ? token : "";
  else return localStorage.getItem("VUE_APP_TOKEN") || "";
}

export function logout() {
  const loginPath = window.location.pathname.startsWith("/en")
    ? "/en/login"
    : "/ar/login";
  if (isKeycloakAuth) {
    localStorage.setItem("CHECKED_KEYCLOAK_REDIRECT_URI", false);
    localStorage.removeItem("VUE_APP_TOKEN");
    token = "";
    _keycloak.logout({
      redirectUri: window.location.origin + loginPath,
    });
  } else {
    localStorage.setItem("CHECKED_KEYCLOAK_REDIRECT_URI", false);
    localStorage.removeItem("VUE_APP_TOKEN");
    socketConnection.closeSocketConnection();
    window.location.href = loginPath;
  }
}

export function translations() {
  /*global require*/
  /*eslint no-undef: "error"*/
  const translations = require.context(
    "./translations",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  translations.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = translations(key);
    }
  });
  return messages;
}

function setLocalStorage(token_expiry, token) {
  localStorage.setItem("isKeycloakAuth", isKeycloakAuth);

  if (isKeycloakAuth) {
    if (token) localStorage.setItem("VUE_APP_TOKEN", token);
    localStorage.setItem(
      "VUE_APP_SESSION_IDLE",
      (token_expiry - 60 - Date.now() / 1000) * 1000
    );
  } else {
    localStorage.setItem(
      "VUE_APP_SESSION_IDLE",
      process.env.VUE_APP_SESSION_IDLE
    );
  }

  localStorage.setItem(
    "VUE_APP_CORE_SERVER_ORIGIN",
    process.env.VUE_APP_CORE_BACKEND_URL
  );

  localStorage.setItem(
    "VUE_APP_GOOGLE_API_KEY",
    process.env.VUE_APP_GOOGLE_API_KEY || ""
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_POS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_POS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_EVENTS_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_EVENTS_PLUGIN_LANGUAGE_BUTTON || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_CATALOGUE_PLUGIN_SOCIAL_ICONS || true
  );

  localStorage.setItem(
    "VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_CATALOGUE_PLUGIN_LANGUAGE_BUTTON || true
  );
  localStorage.setItem(
    "VUE_APP_CHECKOUT_PLUGIN_SOCIAL_ICONS",
    process.env.VUE_APP_CHECKOUT_PLUGIN_SOCIAL_ICONS || true
  );
  localStorage.setItem(
    "VUE_APP_CHECKOUT_PLUGIN_LANGUAGE_BUTTON",
    process.env.VUE_APP_CHECKOUT_PLUGIN_LANGUAGE_BUTTON || true
  );
  localStorage.setItem("MERCHANT_ID", process.env.MERCHANT_ID || "");
  localStorage.setItem(
    "VUE_APP_REAL_ESTATE_API_GATEWAY_URL",
    process.env.VUE_APP_REAL_ESTATE_API_GATEWAY_URL
  );
}

function setConfigToLocalStorage(config) {
  localStorage.setItem("CSP_HEADER", config.csp_header || "");
}

async function getConfig() {
  try {
    const res = await getMerchantConfig();
    return res.data;
  } catch (err) {
    if (err.response.status === 401) {
      localStorage.removeItem("VUE_APP_TOKEN");
      return getConfig();
    }

    return {};
  }
}

async function getUserDetail() {
  try {
    const res = await getUserDetails();
    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      logout();
    }
    console.log("Error while fetching user details: ", err);
    return {};
  }
}

async function getSections() {
  try {
    const res = await getSectionsConfig();
    return res.data;
  } catch (err) {
    console.log("Error while fetching sections: ", err);
    return {};
  }
}

async function getTranslations() {
  try {
    const cachedTranslations = localStorage.getItem("cachedTranslations");

    if (cachedTranslations) {
      const cachedTimestamp = parseInt(
        localStorage.getItem("cachedTimestamp"),
        10
      );
      if (cachedTimestamp && !isNaN(cachedTimestamp)) {
        const currentTime = new Date().getTime();
        const cacheDuration = 24 * 60 * 60 * 1000;
        if (currentTime - cachedTimestamp < cacheDuration) {
          return JSON.parse(cachedTranslations);
        }
      }
    }

    const { data } = await loadTranslations();

    localStorage.setItem("cachedTranslations", JSON.stringify(data));
    localStorage.setItem("cachedTimestamp", new Date().getTime());

    return data;
  } catch (err) {
    console.log("Error while fetching translations", err);
    return {};
  }
}

function checkIsMerchantAdmin(roles) {
  return roles.includes(MERCHANT_ADMIN_ROLE);
}

const refreshToken = async () => {
  await _keycloak
    .updateToken(-1)
    .then(() => {
      if (token) {
        token = _keycloak.idToken;
      }
    })
    .catch((err) => {
      console.log("Error: ", err);
      logout();
    });
};

const setLocale = (userLocaleFromKeycloak) => {
  if (!window.location.pathname.startsWith(`/${userLocaleFromKeycloak}`)) {
    if (
      window.location.pathname.startsWith("/en") ||
      window.location.pathname.startsWith("/ar")
    ) {
      if (
        localStorage.getItem("CHECKED_KEYCLOAK_REDIRECT_URI") !== "true" &&
        userLocaleFromKeycloak
      ) {
        window.location.href =
          `/${userLocaleFromKeycloak}/` +
          window.location.pathname.split("/").slice(2).join("/") +
          (window.location.search || "");
      }
    } else {
      window.location.href =
        `/${userLocaleFromKeycloak || "en"}` +
        window.location.pathname +
        (window.location.search || "");
    }
  }
  localStorage.setItem("CHECKED_KEYCLOAK_REDIRECT_URI", true);
};

start();
